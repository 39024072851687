<template>
  <div class="checkBackground">
    <div class="checkBlock">
      <div class="h2Price">
        <h2>product price</h2>
        <button
          class="btnCancel"
          @click="$emit('cancel-item')"
        >
          ✘
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CreateEvent',
  data() {
    return {
      itemPrice: '',
    };
  },
  mounted() {
    this.$refs.inputPrice.focus();
  },
};
</script>

<style scoped>
  .checkBackground {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(138, 138, 138, 0.5);
    position: absolute;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .checkBlock {
    top: 20%;
    left: 35%;
    right: 35%;
    bottom: 30%;
    position: absolute;
    border: 1px solid black;
    background: #b9b6dd;
  }
  .h2Price {
    text-transform: uppercase;
    border-bottom: 1px solid black;
  }
  .btnCancel {
    width: 20px;
    height: 20px;
    background-color: #926BC6;
    font-size: 14px;
    display: flex;
    justify-content: center;
    position: absolute;
    top: 0;
  }
  .btnCancel:hover {
    background-color: #C2A2DA;
  }
</style>
